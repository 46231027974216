import { ApiService } from "../abstractions/api.service";

import { Forwarder } from "./forwarder";
import { ForwarderOptions } from "./forwarder-options";

export class SimpleLoginForwarder implements Forwarder {
  async generate(apiService: ApiService, options: ForwarderOptions): Promise<string> {
    if (options.apiKey == null || options.apiKey === "") {
      throw "Invalid SimpleLogin API key.";
    }
    const requestInit: RequestInit = {
      redirect: "manual",
      cache: "no-store",
      method: "POST",
      headers: new Headers({
        Authentication: options.apiKey,
        "Content-Type": "application/json",
      }),
    };
    let url = "https://app.simplelogin.io/api/alias/random/new";
    if (options.website != null) {
      url += "?hostname=" + options.website;
    }
    requestInit.body = JSON.stringify({
      note:
        (options.website != null ? "Website: " + options.website + ". " : "") +
        "Generated by Bitwarden.",
    });
    const request = new Request(url, requestInit);
    const response = await apiService.nativeFetch(request);
    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      return json.alias;
    }
    if (response.status === 401) {
      throw "Invalid SimpleLogin API key.";
    }
    try {
      const json = await response.json();
      if (json?.error != null) {
        throw "SimpleLogin error:" + json.error;
      }
    } catch {
      // Do nothing...
    }
    throw "Unknown SimpleLogin error occurred.";
  }
}
