<div class="container page-content">
  <div class="row">
    <div class="col-3">
      <div class="groupings">
        <div class="content">
          <div class="inner-content">
            <app-vault-filter
              #vaultFilter
              [activeFilter]="activeFilter"
              (activeFilterChanged)="applyVaultFilter($event)"
              (onSearchTextChanged)="filterSearchText($event)"
              (onAddFolder)="addFolder()"
              (onEditFolder)="editFolder($event)"
            ></app-vault-filter>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'col-6': isShowingCards, 'col-9': !isShowingCards }">
      <app-vault-header
        [activeFilter]="activeFilter"
        (activeFilterChanged)="applyVaultFilter($event)"
        [actionPromise]="vaultItemsComponent.actionPromise"
        (onAddCipher)="addCipher()"
      ></app-vault-header>
      <app-callout type="warning" *ngIf="activeFilter.isDeleted" icon="bwi-exclamation-triangle">
        {{ trashCleanupWarning }}
      </app-callout>
      <app-vault-items
        [activeFilter]="activeFilter"
        (activeFilterChanged)="applyVaultFilter($event)"
        (onCipherClicked)="navigateToCipher($event)"
        (onAttachmentsClicked)="editCipherAttachments($event)"
        (onAddCipher)="addCipher()"
        (onShareClicked)="shareCipher($event)"
        (onEditCipherCollectionsClicked)="editCipherCollections($event)"
        (onCloneClicked)="cloneCipher($event)"
        (onOrganzationBadgeClicked)="applyOrganizationFilter($event)"
      >
      </app-vault-items>
    </div>
    <div class="col-3">
      <div class="card border-warning mb-4" *ngIf="showUpdateKey">
        <div class="card-header bg-warning text-white">
          <i class="bwi bwi-exclamation-triangle bwi-fw" aria-hidden="true"></i>
          {{ "updateKeyTitle" | i18n }}
        </div>
        <div class="card-body">
          <p>{{ "updateEncryptionKeyShortDesc" | i18n }}</p>
          <button class="btn btn-block btn-outline-secondary" type="button" (click)="updateKey()">
            {{ "updateEncryptionKey" | i18n }}
          </button>
        </div>
      </div>

      <app-low-kdf class="d-block mb-4" *ngIf="showLowKdf"> </app-low-kdf>

      <app-verify-email
        *ngIf="showVerifyEmail"
        class="d-block mb-4"
        (onVerified)="emailVerified($event)"
      ></app-verify-email>

      <div class="card border-warning mb-4" *ngIf="showBrowserOutdated">
        <div class="card-header bg-warning text-white">
          <i class="bwi bwi-exclamation-triangle bwi-fw" aria-hidden="true"></i>
          {{ "updateBrowser" | i18n }}
        </div>
        <div class="card-body">
          <p>{{ "updateBrowserDesc" | i18n }}</p>
          <a
            class="btn btn-block btn-outline-secondary"
            target="_blank"
            href="https://browser-update.org/update-browser.html"
            rel="noopener"
          >
            {{ "updateBrowser" | i18n }}
          </a>
        </div>
      </div>
      <div class="card border-success mb-4" *ngIf="showPremiumCallout">
        <div class="card-header bg-success text-white">
          <i class="bwi bwi-star-f bwi-fw" aria-hidden="true"></i> {{ "goPremium" | i18n }}
        </div>
        <div class="card-body">
          <p>{{ "premiumUpgradeUnlockFeatures" | i18n }}</p>
          <a
            class="btn btn-block btn-outline-secondary"
            routerLink="/settings/subscription/premium"
          >
            {{ "goPremium" | i18n }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #attachments></ng-template>
<ng-template #folderAddEdit></ng-template>
<ng-template #cipherAddEdit></ng-template>
<ng-template #share></ng-template>
<ng-template #collections></ng-template>
<ng-template #updateKeyTemplate></ng-template>
