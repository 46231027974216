<div
  [ngClass]="width"
  class="tw-flex tw-flex-col tw-overflow-hidden tw-rounded tw-border tw-border-solid tw-border-secondary-300 tw-bg-text-contrast tw-text-main"
  @fadeIn
>
  <div
    class="tw-flex tw-items-center tw-gap-4 tw-border-0 tw-border-b tw-border-solid tw-border-secondary-300 tw-p-4"
  >
    <h1 bitDialogTitleContainer class="tw-mb-0 tw-grow tw-text-lg tw-uppercase">
      <ng-content select="[bitDialogTitle]"></ng-content>
    </h1>
    <button
      type="button"
      bitIconButton="bwi-close"
      buttonType="main"
      size="default"
      bitDialogClose
      [attr.title]="'close' | i18n"
      [attr.aria-label]="'close' | i18n"
    ></button>
  </div>

  <div class="tw-overflow-y-auto tw-pb-8" [ngClass]="{ 'tw-p-4': !disablePadding }">
    <ng-content select="[bitDialogContent]"></ng-content>
  </div>

  <div
    class="tw-border-0 tw-border-t tw-border-solid tw-border-secondary-300 tw-bg-background-alt tw-p-4"
  >
    <ng-content select="[bitDialogFooter]"></ng-content>
  </div>
</div>
