<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<ng-container *ngFor="let breadcrumb of beforeOverflow; let last = last">
  <ng-container *ngIf="breadcrumb.route">
    <a
      bitLink
      linkType="primary"
      class="tw-my-2 tw-inline-block"
      [routerLink]="breadcrumb.route"
      [queryParams]="breadcrumb.queryParams"
    >
      <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngIf="!breadcrumb.route">
    <button
      bitLink
      linkType="primary"
      class="tw-my-2 tw-inline-block"
      (click)="breadcrumb.onClick($event)"
    >
      <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
    </button>
  </ng-container>
  <i *ngIf="!last" class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>
</ng-container>

<ng-container *ngIf="hasOverflow">
  <i *ngIf="beforeOverflow.length > 0" class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>

  <button
    bitIconButton="bwi-ellipsis-h"
    [bitMenuTriggerFor]="overflowMenu"
    size="small"
    aria-haspopup
  ></button>

  <bit-menu #overflowMenu>
    <ng-container *ngFor="let breadcrumb of overflow">
      <ng-container *ngIf="breadcrumb.route">
        <a
          bitMenuItem
          linkType="primary"
          [routerLink]="breadcrumb.route"
          [queryParams]="breadcrumb.queryParams"
        >
          <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="!breadcrumb.route">
        <button bitMenuItem linkType="primary" (click)="breadcrumb.onClick($event)">
          <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
        </button>
      </ng-container>
    </ng-container>
  </bit-menu>
  <i class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>

  <ng-container *ngFor="let breadcrumb of afterOverflow; let last = last">
    <ng-container *ngIf="breadcrumb.route">
      <a
        bitLink
        linkType="primary"
        class="tw-my-2 tw-inline-block"
        [routerLink]="breadcrumb.route"
        [queryParams]="breadcrumb.queryParams"
      >
        <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
      </a>
    </ng-container>
    <ng-container *ngIf="!breadcrumb.route">
      <button
        bitLink
        linkType="primary"
        class="tw-my-2 tw-inline-block"
        (click)="breadcrumb.onClick($event)"
      >
        <ng-container [ngTemplateOutlet]="breadcrumb.content"></ng-container>
      </button>
    </ng-container>
    <i *ngIf="!last" class="bwi bwi-angle-right tw-mx-1.5 tw-text-main"></i>
  </ng-container>
</ng-container>
