<ng-select
  [items]="baseItems"
  [(ngModel)]="selectedItems"
  (ngModelChange)="onChange($event)"
  (blur)="onBlur()"
  bindLabel="listName"
  groupBy="parentGrouping"
  [placeholder]="placeholder"
  [loading]="loading"
  [loadingText]="loadingText"
  notFoundText="{{ 'multiSelectNotFound' | i18n }}"
  clearAllText="{{ 'multiSelectClearAll' | i18n }}"
  [multiple]="true"
  [selectOnTab]="true"
  [closeOnSelect]="false"
  (close)="onDropdownClosed()"
  [disabled]="disabled"
  [clearSearchOnAdd]="true"
  [labelForId]="labelForId"
  appendTo="body"
>
  <ng-template ng-loadingspinner-tmp>
    <i class="bwi bwi-spinner bwi-spin tw-mr-1" [title]="loadingText" aria-hidden="true"></i>
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <button
      type="button"
      bitBadge
      badgeType="primary"
      class="tw-mr-1 disabled:tw-border-0"
      [disabled]="disabled"
      (click)="clear(item)"
    >
      <i
        *ngIf="item.icon != null"
        class="tw-mr-1 bwi bwi-fw {{ item.icon }}"
        aria-hidden="true"
      ></i>
      {{ item.labelName }}
      <i class="bwi bwi-fw bwi-close bwi-sm tw-ml-1" aria-hidden="true"></i>
    </button>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <div class="tw-flex">
      <div class="tw-w-7 tw-flex-none">
        <i *ngIf="isSelected(item)" class="bwi bwi-fw bwi-check" aria-hidden="true"></i>
      </div>
      <div class="tw-mr-2 tw-flex-initial">
        <i *ngIf="item.icon != null" class="bwi bwi-fw {{ item.icon }}" aria-hidden="true"></i>
      </div>
      <div class="tw-flex-1">
        {{ item.listName }}
      </div>
    </div>
  </ng-template>
</ng-select>
